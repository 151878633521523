<template>
    <div class="list-group-item" style="overflow: hidden;">
       <!--   v-if="activityItem.User != null && activityItem.Device != null" -->
       <!-- style="display: -webkit-box !important;" -->
        <div class="row">
            <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                    <router-link
                        v-if="activityItem.Location && activityItem.Company"
                        :to="{
                            name: 'profile',
                            params: {
                                locationId: activityItem.Location.Id,
                                companyId: activityItem.Company.Id,
                                userId: activityItem.User.Id,
                            },
                        }"
                    >
                    <img
                        class="avatar-img rounded-circle"
                        :src="activityItem.User.SmallImageUrl"
                        v-if="activityItem.User.SmallImageUrl"
                    />
                    <!-- class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary" -->
                    <div
                        v-else                                                
                    >
                        <!-- <i class="fi flaticon-key"></i> -->
                        <img :src="'/images/person_MyZesec_64dp.png'" class="avatar-img rounded-circle"/>
                    </div>
                    </router-link>
                    <router-link
                        v-else
                        :to="{
                            name: 'profile',
                            params: {
                                // locationId: activityItem.Location.Id,
                                // companyId: activityItem.Company.Id,
                                userId: activityItem.User.Id,
                            },
                        }"
                    >
                    <div
                        class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary"
                    >
                        <i class="fi flaticon-door"></i>
                    </div></router-link>
                </div>
            </div>
            <div class="col ml-n2">
                <p class="small text-gray-700 mb-0">
                    <transformed-link :link="getMessage"></transformed-link>
                </p>
                <small class="text-muted">{{ getDate }} {{ getTime }}</small>
            </div>
        </div>
        <!-- / .row -->
    </div>
</template>
<script>
import activityHelpersMixin from "./mixins/activityHelpersMixin";

export default {
    mixins: [activityHelpersMixin],
    props: ["activityItem"],
    computed: {        
        getMessage() {
            let keyNameNew = ''
            if(this.activityItem.Key != null){
                keyNameNew = this.activityItem.Key;
                if(keyNameNew.Name != null && keyNameNew.Name.includes("Key of: ")){
                    keyNameNew.Name = keyNameNew.Name.replace("Key of: ","");                
                }
            }
            return this.$t("activity.devices_connected", {
                userName: this.userLink(this.activityItem.User),
                keyName: this.keyLink(keyNameNew),
                doorName: this.doorLink(this.activityItem.Device),
                locationName: this.locationLink(),
                companyName: this.companyLink(),
            });
        },
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>