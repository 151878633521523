<template>
    <b-list-group-item class="flex-column align-items-start" v-if="!isMobile">
        <b-row align-v="center" style="height: 100px;">
            <b-col lg="3" md="6" cols="6">
                <b-row>
                    <b-col cols="12">
                        <router-link
                        class="ml-2"
                            :to="{
                                name: 'profile',
                                params: {
                                    locationId: $route.params.locationId,
                                    companyId: $route.params.companyId,
                                    userId: user.User.Id,
                                },
                        }"
                    >
                    <img
                        class="avatar avatar-xs align-middle avatar-img rounded-circle"
                        size="sm"
                        variant="secondary"
                        :src="user.User.SmallImageUrl"
                        v-if="user.User.SmallImageUrl"
                    />
                    <div
                        v-else
                        class="avatar avatar-xs align-middle mr-2"
                        style="margin-bottom: 0.375rem !important;"
                    >
                        <img :src="'/images/person_MyZesec_64dp.png'" style="margin-right: 10px; width: 32px; height: 32px;"/>
                    </div>
                     <span class="mr-auto">{{" "+userFName(user.User.FirstName)+" "+userLName(user.User.LastName)}}</span>
                     </router-link>
                    </b-col>
                </b-row>
            </b-col>
            <b-col lg="3" md="6" cols="6">
                {{ keyType }}
                &nbsp;
                <b-badge v-if="_showTemporaryTime()" :variant="getTimeBadge(user.Calendar.AccessIntervals[0].EndDate)">{{ getTimeStr(user.Calendar.AccessIntervals[0].EndDate) }}</b-badge>
            </b-col>
            <b-col lg="2" md="6" cols="6">
                {{(user.MayShare || _managementRole(user))
                        ? $t("keys.share_allowed")
                        : $t("keys.share_disallowed")}}
            </b-col>
            <b-col lg="3" md="6" cols="6">
                <b-form-checkbox v-if="isPermanent && isIntercomVisible" @change="VisibleInIntercomChange()" v-model="isVisibleInIntercom">
                    {{$t('users.visible_in_intercom')}}
                </b-form-checkbox>
                <div class="custom-control" v-else></div>
            </b-col>
            <div class="col-auto">
                <div v-b-tooltip.hover.left='$t("buttons.key_access_info")'>
                  <img src="@/assets/icons/info.svg" height="24" width="24" alt="info"/>
                </div>
               <!-- Dropdown -->
               <!--  variant="outline" -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                    v-if="!isOwner && false"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fa fa-cog fa-fw" aria-hidden="true"></i> -->
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteUser(user.User)"
                        >{{ $t("buttons.remove_door_user") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editKey()"
                        >{{ $t("buttons.edit") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
        </b-row>
    </b-list-group-item>

    <!-- new view -->
    <b-list-group-item class="flex-column align-items-start" v-else>
                <b-row style="height: 100px;">
                    <b-col lg="4" md="4" cols="4">
                            <router-link
                        class="ml-2"
                            :to="{
                                name: 'profile',
                                params: {
                                    locationId: $route.params.locationId,
                                    companyId: $route.params.companyId,
                                    userId: user.User.Id,
                                },
                        }"
                    >
                    <img
                        class="avatar avatar-xs align-middle avatar-img rounded-circle"
                        size="sm"
                        variant="secondary"
                        :src="user.User.SmallImageUrl"
                        v-if="user.User.SmallImageUrl"
                        style="margin-right: 10px;"
                    />
                    <div
                        v-else
                        class="avatar avatar-xs align-middle mr-2"
                        style="margin-bottom: 0.375rem !important;"
                    >
                        <img :src="'/images/person_MyZesec_64dp.png'" style="margin-right: 10px; width: 32px; height: 32px;"/>
                    </div>
                     <p style="white-space: pre;">{{userFName(user.User.FirstName) +"\n"+ userLName(user.User.LastName)}}</p>
                     </router-link>
                    </b-col>
                    <!-- <b-col lg="3" md="3" cols="3">{{ keyType }}</b-col> -->
                    <b-col lg="6" md="6" cols="6">
                        {{ keyType }}
                        &nbsp;
                        <b-badge v-if="_showTemporaryTime()" :variant="getTimeBadge(user.Calendar.AccessIntervals[0].EndDate)">{{ getTimeStr(user.Calendar.AccessIntervals[0].EndDate) }}</b-badge>
                    </b-col>
                    <b-col>
                        <div v-b-tooltip.hover.left='$t("buttons.key_access_info")'>
                          <img src="@/assets/icons/info.svg" height="24" width="24" alt="info"/>
                        </div>
                        <!--  -->
                        <b-dropdown
                            variant="outline"
                            toggle-class="dropdown-ellipses dropdown-toggle"
                            no-caret
                            right
                            toggle-tag="a"
                            v-if="!isOwner && false"
                            style="margin-top:-11px!important; margin-right: -63px; display: flex!important; left: -32px !important;"
                        >
                            <template v-slot:button-content>
                                <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                            </template>
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deleteUser(user.User)"
                                >{{ $t("buttons.remove_door_user") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="editKey()"
                                >{{ $t("buttons.edit") }}</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-col>
                </b-row>
            <b-row>
            <b-col lg="4" md="4" cols="4"></b-col>
            <b-col lg="6" md="6" cols="6">
                {{(user.MayShare || _managementRole(user))
                        ? $t("keys.share_allowed")
                        : $t("keys.share_disallowed")}}
            </b-col>
            <b-col></b-col>
            </b-row>
            <b-row>
            <b-col lg="4" md="4" cols="4"></b-col>
            <b-col lg="6" md="6" cols="6">
                <b-form-checkbox v-if="isPermanent && isIntercomVisible" @change="VisibleInIntercomChange()" v-model="isVisibleInIntercom">
                    {{$t('users.visible_in_intercom')}}
                </b-form-checkbox>
                <div class="custom-control" v-else></div>
            </b-col>
            <b-col></b-col>
            </b-row>
    </b-list-group-item>
</template>
<script>
import {
    ROLE_COMPANY_MANAGER,
    ROLE_COMPANY_OWNER, ROLE_KEY_MANAGER,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
    ROLE_LOCATION_MANAGER
} from "@/acl/roles";
import { SET_MODAL_DATA } from "@/store/types/global";
import {
    M_MEMBER_REINVITE_FORM,
    M_DELETE_DOOR_USER,
} from "@/components/modals/types";

import { mapGetters } from "vuex";

import modalMixin from "@/mixins/modalMixin";
import MemberController from "../../api/member";
import {
    DEVICE_USERS_GET,
} from "@/store/types/devices";

export default {
    mixins: [modalMixin],
    name: "user-short-list-item",
    data() {
        return {
            isVisibleInIntercom: this.user.VisibleInIntercom,
            isOwner: false,
            keyType: this.$t("keys.type_temporary"),
            keyTypeColor: "light",
            isPermanent: true,
            isIntercomVisible: false,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight
        };
    },
    props: ["user", "showDeleting"],
    computed: {
        ...mapGetters(["getActiveDevice"]),
    },
    created() {
        this.updateContext()
        this.isMobile = window.addEventListener("resize", this.onResize);
        this.isMobile = window.addEventListener("onload", this.onLoad);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("onload", this.onLoad);
        this.isMobile = false;
    },
    methods: {
        updateContext() {
            this.isOwner = this._isOwner();
            this.keyType = this._keyType();
            this.isPermanent = this._isPermanent();
            this.isIntercomVisible = this._isIntercomVisible();
        },
        getTimeStr(time) {
            let date = this.$moment(time + 'Z')
            return date.format("YYYY/MM/DD HH:mm:ss")
        },
        getTimeBadge(time) {
            try {
                let d1 = new Date(time + 'Z')
                let diff = ((new Date()).getTime() - d1.getTime()) / 1000 / 60;
                if (diff < 0)
                    return "success"
                else
                    return "secondary"
            } catch(err){
                return "danger"
            }
        },
        userFName(FirstName){
            FirstName = this.user.User.FirstName;
            if(FirstName === null || FirstName === "null" || FirstName === ""){
                FirstName = '';
            }
            return FirstName;
        },
        userLName(LastName){
            LastName = this.user.User.LastName;
            if(LastName === null  || LastName === "null" || LastName === ""){
                LastName = '';
            }
            return LastName;
        },
        _managementRole(user) {
            return (
                user.Role?.Name == ROLE_COMPANY_OWNER ||
                user.Role?.Name == ROLE_COMPANY_MANAGER ||
                user.Role?.Name == ROLE_LOCATION_MANAGER ||
                user.Role?.Name == ROLE_KEY_MANAGER
            );
        },
        _isOwner() {
            return (
                !this.user.Role && this.user.Key.OwnerId == this.user.User.Id
            );
        },

        async VisibleInIntercomChange() {
            var visibleInIntercomState = 0
            if (this.isVisibleInIntercom) {
                visibleInIntercomState = 1
            } else {
                visibleInIntercomState = 0
            }

           await MemberController.updateMemberByKeyIdUserIdAndLocationIdShouldShowInIntercomList(this.user.KeyId, this.user.UserId, this.user.Key.LocationId, visibleInIntercomState);

        },

        _keyType() {
            if (this._isOwner()) {
                this.keyTypeColor = "zsprimary";
                return this.$t("keys.type_owner");
            } else if (this.user.Role.Name == ROLE_KEY_PERMANENT) {
                return this.$t("keys.type_permanent");
            } else if (
                this.user.Calendar.AccessIntervals[0].DailyAccesses.length == 0
            ) {
                return this.$t("keys.type_temporary");
            }
            return this.$t("keys.type_recurring");
        },
        _isPermanent(){
            if(this.user.Role.Name == ROLE_KEY_PERMANENT && this.user.User.Type == "Full") {
                return true;
            }else if(this.user.User.Type == "Light"){
                return false;
            }else{
                return false;
            }
        },
        _isTemporary(){
            return (this.user.Role.Name == ROLE_KEY_TEMPORARY)
        },
        _showTemporaryTime(){
            return this._isTemporary() && this.user.Calendar?.AccessIntervals.length > 0 && this.user.Calendar?.AccessIntervals[0].EndDate
        },
        _isIntercomVisible(){
            if(this.getActiveDevice.Device.ProviderType === 3 || this.getActiveDevice.Device.ProviderType === 5 || this.getActiveDevice.Device.ProviderType === 6 || this.getActiveDevice.Device.ProviderType === 7){
                return true;
            }else{
                return false;
            }
        },
        editKey() {
            this.$store.commit(SET_MODAL_DATA, {
                data: { access: this.user },
                type: M_MEMBER_REINVITE_FORM,
            });
            this.$bvModal.show(M_MEMBER_REINVITE_FORM);
            if(this.$bvModal.hide(this.type)){
                 this.$store.dispatch(DEVICE_USERS_GET, this.$route.params.doorId);
            }
        },
        deleteUser() {
            this.$store.commit(SET_MODAL_DATA, {
                data: { access: this.user },
                type: M_DELETE_DOOR_USER,
            });
            this.showModal(
                {
                    user: {
                        Key: this.user.Key,
                        Door: this.getActiveDevice,
                        Receiver: this.user.User,
                    },
                },
                M_DELETE_DOOR_USER
            );
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 1200){
                this.isMobile = true
            }else{
                this.isMobile = false
            }
            return this.isMobile;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
            return this.isMobile;
        },
    },
    watch: {
        user: function() {
            this.updateContext()
        }
    },
};
</script>
<style scoped>
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
